import { AfterContentChecked, AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'po-page-default[app-control-height]',
  standalone: true,
  imports: [],
  templateUrl: './control-height.component.html',
  styleUrl: './control-height.component.css'
})
export class ControlHeightComponent {
  private mutationObserver!: MutationObserver;

  constructor(private el: ElementRef,
              private renderer: Renderer2){
  }



}
