@if (authenticatedUser) {
  <div class="po-wrapper"  #parentElement>
    <app-toolbar></app-toolbar>

    <po-menu
      [p-menus]="menus"
      [p-short-logo]="shortLogoApp"
      [p-collapsed]="true">
      <div *p-menu-header-template>
        <div style="margin: auto; margin-top: 10%;">
          <img src="{{ logoApp }}" >
        </div>
      </div>
    </po-menu>

    <po-container [p-no-padding]="true" [p-no-border]="true">
      <router-outlet></router-outlet>
    </po-container>
  </div>
} @else {
  <router-outlet></router-outlet>
}
