import { HttpErrorResponse, HttpHandlerFn, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { EMPTY, catchError, concatMap, finalize, throwError } from "rxjs";

import { TokenManageService } from "../../shared/services/token-manager.service";

export const ErrorApiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {

    const router = inject(Router);
    const tokenManageService = inject(TokenManageService);

    /** Interceptor responsável por escutar e tratat os retornos das APIs */
    return next(request).pipe(

        /** Tratativas para retorno de erro das APIs */
        catchError((error:HttpErrorResponse)=>{

            /** Retornar o erro 401 Usuário não atenticado realiza a chamada do refreshToken e se não conseguir gerar um novo token então redireciona para a pagina de login */
            if (error.status === HttpStatusCode.Unauthorized && !tokenManageService.isRefreshing){
                tokenManageService.isRefreshing = true;

                return tokenManageService.refreshToken().pipe(
                    finalize(() => (tokenManageService.isRefreshing = false)),
                    concatMap((response: any)=>{
                        tokenManageService.setDataToken(response)
                        const requestClone = tokenManageService.addTokenHeader(request);
                        return next(requestClone)
                    }),
                    catchError(() => {
                        tokenManageService.clearToken();
                        router.navigate(['login']);
                        return EMPTY
                    })
                );
            }

            return throwError(() => error)
        })
    )
}
