import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { EMPTY } from "rxjs";

import { URL } from "../configs/url";
import { TokenManageService } from "../../shared/services/token-manager.service";
import { ProAppConfigService } from "@totvs/protheus-lib-core";

export const ApiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {

    const tokenManageService = inject(TokenManageService)
    const proAppConfigService = inject(ProAppConfigService)
    const router = inject(Router)

    /** Não realiza nenhuma ação quando é chamada interna do Protheus */
    if (proAppConfigService.insideProtheus()){
        return next(request);
    }

    /** Não realiza nenhuma ação para a URL do appConfig */
    if (request.url.includes('assets/data/appConfig.json')){
        return next(request);
    }

    /** Não realiza nenhuma ação para a URL de atenticação  */
    if (request.url.indexOf(URL.token) >= 0 ){
        return next(request);
    }

    /** Retorno vazio quando já estiver realizando o refresh token */
    if (tokenManageService.isRefreshing){
        return EMPTY;
    }

    /** Se ainda não tiver gerado um token então direciona para a página de lojgin */
    const dataToken = tokenManageService.getDataToken();

    if (!dataToken || !dataToken.access_token){
        router.navigateByUrl('login');
        return EMPTY;
    }

    /** Adicionar o token nas demais APIs */
    const requestClone = tokenManageService.addTokenHeader(request)

    return next(requestClone);
}
