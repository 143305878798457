import { provideRouter } from '@angular/router';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PoHttpRequestModule, PoModule } from '@po-ui/ng-components';
import { ProtheusLibCoreModule } from '@totvs/protheus-lib-core';

import { routes } from './app.routes';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { ErrorApiInterceptor } from './core/interceptors/error-api.interceptor';
import { BrowserModule } from '@angular/platform-browser';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([ApiInterceptor,ErrorApiInterceptor])),
    importProvidersFrom([BrowserModule, BrowserAnimationsModule, PoHttpRequestModule, PoModule, ProtheusLibCoreModule])
  ],
};
