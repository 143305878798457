import { UserService } from './../../../shared/services/user.service';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PoMenuItem, PoMenuModule, PoModule, PoPageModule, PoToolbarAction, PoToolbarModule, PoToolbarProfile } from '@po-ui/ng-components';
import { ProAppConfigService } from '@totvs/protheus-lib-core';
import { TokenManageService } from '../../../shared/services/token-manager.service';
import { AccountService } from '../../../shared/services/account.service';
import { User } from '../../models/user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [PoMenuModule, PoToolbarModule, PoPageModule, PoModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent {
  @ViewChild('iconExit') iconExit!: TemplateRef<void>;

  user:any;
  profile: PoToolbarProfile = {
    subtitle: 'dev@po-ui.com.br',
    title: 'Mr. Dev PO'
  };

  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-clipboard',  label: 'Currículo', action: this.chamaRota.bind(this,'/curriculum') },
    { icon: this.iconExit, label: 'Sair', type: 'danger',  action: this.closeApp.bind(this) }
  ];

  logoApp:string = '';
  shortLogoApp:string = ''
  subscriptions:Subscription[] = [];

  readonly menus: Array<PoMenuItem> = [
    { shortLabel:'Home', label: 'Home',             icon: 'po-icon-home', action: this.chamaRota.bind(this,'/home') },
    { shortLabel:'Análise de Cargo', label: 'Análise de Cargo', icon: 'po-icon-waiter', action: this.chamaRota.bind(this,'/job-analisys')  },
    { shortLabel:'Currículo', label: 'Currículo',        icon: 'po-icon-clipboard', action: this.chamaRota.bind(this,'/curriculum')  },
    { shortLabel:'Sair', label: 'Sair',             icon: 'po-icon-exit', action: this.closeApp.bind(this) }
  ];

  constructor(private proAppConfigService: ProAppConfigService,
              private tokenManagerService: TokenManageService,
              private router: Router,
              private accountService: AccountService,
              private userService: UserService){
  }

  ngOnInit(): void {
    /*
    this.userService.getUser().subscribe({
      next: (response) => {
        this.profile.title = response.displayName;
        this.profile.subtitle = response.emails[0].value
      }
    })
    */

    var userSubcription = this.userService.cachedUser().subscribe({
      next: (response:User) => {
        if(response){ this.user = response; }
        console.log("USUARIOOOOOOO ", response)
      }
    });


    this.carregaInjetaParamsIniciais()
  }

  ngAfterViewInit() {
    this.carregaInjetaParamsIniciais()
  }

  ngAfterViewChecked() {
    this.carregaInjetaParamsIniciais()
  }

  chamaRota(rota:string){ this.router.navigate([rota]) }

  /** Realiza o fechamento da aplicaçã se for via Protheus fecha a rotina e se não for Protheus retorna para a pagina de login.*/
  closeApp(): void {
    if (this.proAppConfigService.insideProtheus()) {
      this.proAppConfigService.callAppClose(false);

    } else {
      this.accountService.setAuthenticatedUser(false);
      this.tokenManagerService.clearToken();
      this.router.navigate(['login']);
    }
  }

  carregaInjetaParamsIniciais(){
    this.profileActions[1].icon = this.iconExit;
    this.logoApp = '../../../../assets/img/Logo EN Azul (3).svg';
    this.shortLogoApp = '../../../../assets/img/LogoENAzulmenor.svg';
  }

}
