<div class="po-wrapper">
  <po-toolbar p-title="ALWAYS MOVING"
    [p-profile]="profile"
    [p-profile-actions]="profileActions">

    <ng-template #iconExit>
      <span class="po-icon po-icon-exit" style="color:rgba(198, 72, 64, 1);"></span>
    </ng-template>

  </po-toolbar>

  <po-menu
    [p-menus]="menus"
    [p-short-logo]="shortLogoApp">
    <div *p-menu-header-template>
      <div style="margin: auto; margin-top: 10%;">
        <img src="{{ logoApp }}" >
      </div>
    </div>
  </po-menu>
</div>
