
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import {
  PoContainerModule,
  PoMenuItem,
  PoMenuModule,
  PoPageModule,
  PoToolbarModule
} from '@po-ui/ng-components';
import { ProAppConfigService, ProtheusLibCoreModule } from '@totvs/protheus-lib-core';

import { TokenManageService } from './shared/services/token-manager.service';
import { AccountService } from './shared/services/account.service';
import { ToolbarComponent } from './core/components/toolbar/toolbar.component';
import { MenuComponent } from './core/components/menu/menu.component';
import { LayoutControlService } from './shared/services/layout-control.service';
import { CommonModule } from '@angular/common';
import { ControlHeightComponent } from './shared/material/control-height/control-height.component';
import { FooterComponent } from "./pages/home/footer/footer.component";
import { URL } from './core/configs/url';
import { CurriculumService } from './pages/curriculum/services/curriculum.service';
import { Experience } from './core/models/experience.interface';
import { UserService } from './shared/services/user.service';
import { User } from './core/models/user.interface';
import { Branch } from './core/models/branch.interface';
import { Affiliate } from './core/models/affiliate.interface';
import { Subscription, filter } from 'rxjs';
import { UtilsService } from './shared/services/utils.service';
import { PoPageDynamicSearchModule } from '@po-ui/ng-templates';
import { IMAGE } from './core/configs/image';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ProtheusLibCoreModule,
    PoMenuModule,
    PoToolbarModule,
    PoPageModule,
    ToolbarComponent,
    MenuComponent,
    PoContainerModule,
    CommonModule,
    ControlHeightComponent,
    FooterComponent,
    PoPageDynamicSearchModule
],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit{
  authenticatedUser: boolean = false;
  private mutationObserver!: MutationObserver;

  private user:any = undefined;
  private branchUser:any = undefined;
  private affiliateUser:any = undefined;
  urls = URL;
  logoApp:string = '';
  shortLogoApp:string = '';
  customLiterals:any = {};
  isDesktop:boolean = true;
  experiencesApp:Array<Experience> = [];
  widthScreen:number = 0;
  containersPagePOUI = {
    '0':'pagedefault',
    '1':'pagesearch'
  };

  containerActived:string = 'pagedefault';

  menus: Array<PoMenuItem> = [
    { shortLabel:'Home', label: 'Home', icon: 'po-icon-home', action: this.callRoute.bind(this,'/home') },
    { shortLabel:'Análise de Cargo', label: 'Análise de Cargo', icon: 'po-icon-waiter', action: this.callRoute.bind(this,'/job-analisys')  },
    { shortLabel:'Currículo', label: 'Currículo', icon: 'po-icon-clipboard', action: this.callRoute.bind(this,'/curriculum')  },
    { shortLabel:'Configurações', label: 'Configurações', icon: 'ph ph-gear', action: this.callRoute.bind(this,'/settings')  },
    { shortLabel:'Sair', label: 'Sair', icon: 'po-icon-exit', action: this.closeApp.bind(this) }
  ];

  private _isAdmin:boolean = false;

  get isAdmin(){
    return this._isAdmin;
  }

  controlAvatar:boolean = false;

  subscriptions:Subscription[] = [];

  constructor(private proAppConfigService: ProAppConfigService,
              private tokenManagerService: TokenManageService,
              private router: Router,
              private accountService: AccountService,
              private el: ElementRef,
              private renderer: Renderer2,
              private curriculumService:CurriculumService,
              private userService:UserService,
              private layoutControl:LayoutControlService,
              private utilsService:UtilsService){
    this.logoApp = IMAGE.logo;
    this.shortLogoApp = IMAGE.shortLogoApp
    this.proAppConfigService.loadAppConfig();
  }

  ngOnInit(): void {
    this.authenticatedUser = false;
    //--- Evento que é disparado quando o usuário é autenticado, para exibir o menu
    this.accountService.showMenuEmitter.subscribe({
      next: (authenticatedUser:any) => {
        this.authenticatedUser = authenticatedUser;

        if(authenticatedUser){
          this.setAppPagePOUI();

          if(!this.user){
            this.updateUser();
          }

          var userSubcription = this.userService.cachedUser().subscribe({
            next: (response:User) => {
              if(response){
                this.user = response;

                this._isAdmin = response.isAdmin;
                //this._isAdmin = true;

                this.setCustomLiterals();

                if(this.user?.avatarId && !this.controlAvatar){
                  var avatarId = String(this.user.avatarId);
                  this.controlAvatar = true;
                  this.userService.getAvatarByCode(avatarId).subscribe({
                    next: (response:any) => {
                      console.log('Sucesso ao consultar avatar: ', response);
                    },
                    error: (error:any) => {
                      this.controlAvatar = false;
                      console.log('Erro ao consultar avatar: ',error);
                    }
                  });
                }

                if(this.user.code){
                  this.updateUserAds(this.user.code);
                }

                if(!this.branchUser){
                  var branchCode = String(response.branchCode).length == 1 ? '0'+response.branchCode : String(response.branchCode);
                  branchCode ?  this.updateBranchUser(branchCode) :  console.error('Valor da filial é inconsistente');
                }

                if(!this.affiliateUser){
                  var affiliateCode = String(response.affiliateCode).length == 1 ? '0'+response.affiliateCode : String(response.affiliateCode);
                  affiliateCode ?  this.updateAffiliateUser(affiliateCode) : console.error('Valor da coligada é inconsistente');
                }
              } else {
                this.user = undefined;
                this.branchUser = undefined;
                this.affiliateUser = undefined;
              }
            },
            error: (error) => { console.error('Falha ao carregar dados do usuário', error) }
          });

          var branchUserSubscription = this.userService.cachedBranchUser().subscribe((response:Branch) => { if(response){ this.branchUser = response; } });
          var affiliateUserSubscription = this.userService.cachedAffiliateUser().subscribe((response:Affiliate) => { if(response){ this.affiliateUser = response } });

          this.subscriptions.push(userSubcription);
          this.subscriptions.push(branchUserSubscription);
          this.subscriptions.push(affiliateUserSubscription);
        }
      }
    });

    if (this.proAppConfigService.insideProtheus()){
      this.accountService.setAuthenticatedUser(true);
    }

    this.initializeMutationObserver();
    this.layoutControl.returnWidthScreen().subscribe({
      next: (response:number) => {
        this.widthScreen = response;
        this.checkIsDesktop(this.widthScreen);
        this.ajustHeightPageDefault();
      }
    });
  }

  setAppPagePOUI(){
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event:NavigationEnd) => {
      if(event.urlAfterRedirects.includes('/settings')){
        this.containerActived = this.containersPagePOUI[1];
      }
    })
  }

  updateUser(){
    this.userService.getUsersByUserCode().subscribe({
      next: (response:any) => { this.utilsService.logSuccess("########################################### SUCESSO AO USUARIO") }
    });
  }

  updateBranchUser(branchCode:string){
    this.userService.getBranchesByCode(branchCode).subscribe({
      next: (response:any) => { this.utilsService.logSuccess("########################################### SUCESSO AO CONSULTAR A FILIAL") }
    });
  }

  updateAffiliateUser(affiliateCode:string){
    this.userService.getAffiliatesByCode(affiliateCode).subscribe({
      next: (response:any) => { this.utilsService.logSuccess("########################################### SUCESSO AO CONSULTAR A COLIGADA") }
    });
  }

  updateUserAds(codeUser:string){
    this.userService.getAdsByUser(codeUser).subscribe({
      next: (response:any) => { this.utilsService.logSuccess("########################################### SUCESSO AO CONSULTAR ADS DO USUÁRIO") }
    });
  }

  updateExperiences(codeUser:string, sort:string){
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@ codeUser"+codeUser+ "!!!!!!!!!!!!! sort: "+sort )
    this.curriculumService.getExperiencesByCode(codeUser,sort).subscribe({
      next: (response:any) => { console.log("################################### UPDATE DAS EXPERIENCIAS:", response) },
      error: (err) => { console.log("################################### ERRO AO ATUALIZAR EXPERIENCIAS:", err) }
    });
  }

  checkIsDesktop(width:number){
    this.isDesktop = width > 480 ? true : false;
  }

  ngAfterViewInit() {}

  ngAfterViewChecked() {}

  ajustHeightPageDefault(){
    const element = this.el.nativeElement.querySelector('.po-page-content');
    if(element){
      var newHeight = this.isDesktop ? '94' : '96.2';
      this.renderer.setStyle(element, 'height', newHeight+'vh');
      this.renderer.setStyle(element, 'max-height', newHeight+'vh');
    }
  }

  callRoute(rota:string){ this.router.navigate([rota]) }

  initializeMutationObserver(){
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        this.ajustHeightPageDefault();
      });
    });

    this.mutationObserver.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  /** Realiza o fechamento da aplicaçã se for via Protheus fecha a rotina e se não for Protheus retorna para a pagina de login.*/
  closeApp(): void {
    this.accountService.closeApp();
  }

  setCustomLiterals(): void {
    let language = this.accountService.getLanguage()

    if (language === 'pt' || language === ''){
      this.customLiterals = {};

      this.menus = [];
      this.menus.push({ shortLabel:'Home', label: 'Home', icon: 'po-icon-home', action: this.callRoute.bind(this,'/home') });
      //this.menus.push({ shortLabel:'Análise de Cargo', label: 'Análise de Cargo', icon: 'po-icon-waiter', action: this.callRoute.bind(this,'/job-analisys')  });
      this.menus.push({ shortLabel:'Currículo', label: 'Currículo', icon: 'po-icon-clipboard', action: this.callRoute.bind(this,'/curriculum')  });
      if(this.isAdmin){
        this.menus.push({ shortLabel:'Configurações', label: 'Configurações', icon: 'ph ph-gear', subItems: [] });
      }
      this.menus.push({ shortLabel:'Sair', label: 'Sair', icon: 'po-icon-exit', action: this.closeApp.bind(this) });

      if(this.isAdmin){
        const indexConfiguration = this.menus.findIndex(menu => menu.shortLabel === 'Configurações');
        this.menus[indexConfiguration].subItems?.push({ label: 'Cadastro de Anúncios', action: this.callRoute.bind(this,'/settings') });
      }

    } else if (language === 'en') {
      this.customLiterals = {};
      this.menus = [];

    }
  }

}
