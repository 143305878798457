
  <po-toolbar 
  [p-title]="customLiterals.title"
  [p-profile]="profile"
  [p-profile-actions]="profileActions">

  <ng-template #iconExit>
    <span class="po-icon po-icon-exit" style="color:rgba(198, 72, 64, 1);"></span>
  </ng-template>

</po-toolbar>
@if (! production) {
  <div class="po-md-12" style="background: red; height: 7px;"></div>
}

