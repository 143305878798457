import { Component, TemplateRef, ViewChild } from '@angular/core';
import { PoToolbarAction, PoToolbarModule, PoToolbarProfile } from '@po-ui/ng-components';
import { ProAppConfigService, ProUserInfoService, ProUserPswretInterface } from '@totvs/protheus-lib-core';
import { TokenManageService } from '../../../shared/services/token-manager.service';
import { Router } from '@angular/router';
import { AccountService } from '../../../shared/services/account.service';
import { UserService } from '../../../shared/services/user.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { User } from '../../models/user.interface';
import { LayoutControlService } from '../../../shared/services/layout-control.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [PoToolbarModule],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.css'
})

export class ToolbarComponent {
  @ViewChild('iconExit') iconExit!: TemplateRef<void>;

  production: boolean = true;
  customLiterals: any = { title: 'Always Moving' }

  widthScreen:number = 0;
  private user:any;
  profile: PoToolbarProfile = {
    subtitle: 'dev@po-ui.com.br',
    title: 'Mr. Dev PO'
  };

  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-clipboard',  label: 'Currículo', action: this.callRoute.bind(this,'/curriculum') },
    { icon: this.iconExit, label: 'Sair', type: 'danger',  action: this.closeApp.bind(this) }
  ];

  constructor(private proAppConfigService: ProAppConfigService,
    private proUserInfoService: ProUserInfoService,
    private router: Router,
    private accountService: AccountService,
    private userService:UserService,
    private utilsService:UtilsService,
    private layoutControl:LayoutControlService){
  }

  ngOnInit(): void {
    //--- Evento que é disparado quando é realiza a troca de linguagem
    this.setCustomLiterals()

    this.production = environment.production; 

    if (this.proAppConfigService.insideProtheus()){
      this.proUserInfoService.pswRet().subscribe( pswret => {
          const proUserPswRet: ProUserPswretInterface = pswret as ProUserPswretInterface;
          this.accountService.login = proUserPswRet.user_name;

          this.userService.getUser().subscribe({
            next: (response) => {
              this.profile.title = response.displayName;
              this.profile.subtitle = response.emails[0].value
            }
          })
        }
      );
    } else {
      this.userService.getUser().subscribe({
        next: (response) => {
          this.profile.title = response.completeName;
          this.profile.subtitle = response.email
        }
      })
    }

    var userSubcription = this.userService.cachedUser().subscribe({
      next: (response:User) => {
        if(response){
          this.applyUserDataInToolbar(response);
        }
      }
    });

    if(!this.user){
      //this.updateUser();
    }

    this.layoutControl.returnWidthScreen().subscribe({
      next: (response:number) => {
        this.widthScreen = response;
      }
    });

    this.userService.cachedAvatarUser().subscribe((response) => {
      //console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH", response)
      if(response?.base64){
        this.profile.avatar = String(response['base64']);
      }
    });
  }

  ngAfterViewInit() {
    this.loadingInjectInitialParams()
  }

  ngAfterViewChecked() {
    this.loadingInjectInitialParams()
  }

  updateUser(){
    this.userService.getUsersByUserCode().subscribe({
      next: (response:any) => { this.utilsService.logSuccess("########################################### SUCESSO AO USUARIO") }
    });
  }

  callRoute(rota:string){ this.router.navigate([rota]) }

  applyUserDataInToolbar(response:any){
    this.profile.subtitle = response.email;
    this.profile.title = `${response.name} ${response.lastName}`;
    //this.profile.avatar = `${URL.imgFolder}/rocket.svg`;
  }

  setCustomLiterals(): void {
    const language = this.accountService.getLanguage();

    if (language === 'pt' || language === ''){
      this.customLiterals = {
        title: 'Always Moving'
      }
    } else if (language === 'en'){
      this.customLiterals = {
        title: 'Always Moving'
      }
    }
  }

  /** Realiza o fechamento da aplicaçã se for via Protheus fecha a rotina e se não for Protheus retorna para a pagina de login.*/
  closeApp(): void {
    this.accountService.closeApp();
  }

  loadingInjectInitialParams(){
    this.profileActions.forEach( (element:PoToolbarAction, index) => {
      if(element.label == 'Sair'){
        element.icon = this.iconExit;
      }
    });
  }
}
